// Generated by Framer (78a4586)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["sXDHTLTmI", "wMIbqcvXO", "hadyDTebR", "gUGs0gxEz", "vlvEtwVCt"];

const serializationHash = "framer-VwvFW"

const variantClassNames = {gUGs0gxEz: "framer-v-1pf3lpq", hadyDTebR: "framer-v-denpk0", sXDHTLTmI: "framer-v-1o9n1z2", vlvEtwVCt: "framer-v-1tocly9", wMIbqcvXO: "framer-v-1xixgdm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.35, ease: [0, 0, 1, 1], type: "tween"}

const transition2 = {delay: 0, duration: 1, ease: [0, 0, 1, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "sXDHTLTmI", "Variant 2": "wMIbqcvXO", "Variant 3": "hadyDTebR", "Variant 4": "gUGs0gxEz", "Variant 5": "vlvEtwVCt"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "sXDHTLTmI"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "sXDHTLTmI", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear9jcxp2 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("wMIbqcvXO"), 1)
})

const onAppeariuzupo = activeVariantCallback(async (...args) => {
await delay(() => setVariant("hadyDTebR"), 1000)
})

const onAppear105kblc = activeVariantCallback(async (...args) => {
await delay(() => setVariant("gUGs0gxEz"), 350)
})

const onAppearx3et1v = activeVariantCallback(async (...args) => {
await delay(() => setVariant("vlvEtwVCt"), 1000)
})

const onAppear15c37s8 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("wMIbqcvXO"), 350)
})

useOnVariantChange(baseVariant, {default: onAppear9jcxp2, gUGs0gxEz: onAppearx3et1v, hadyDTebR: onAppear105kblc, vlvEtwVCt: onAppear15c37s8, wMIbqcvXO: onAppeariuzupo})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({gUGs0gxEz: {value: transition2}, wMIbqcvXO: {value: transition2}}, baseVariant, gestureVariant)}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1o9n1z2", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"sXDHTLTmI"} ref={ref ?? ref1} style={{backgroundColor: "rgb(23, 29, 69)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}} {...addPropertyOverrides({gUGs0gxEz: {"data-framer-name": "Variant 4"}, hadyDTebR: {"data-framer-name": "Variant 3"}, vlvEtwVCt: {"data-framer-name": "Variant 5"}, wMIbqcvXO: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-lwe2hc"} data-framer-name={"Moving Light"} layoutDependency={layoutDependency} layoutId={"dY6PX3PUu"} style={{background: "radial-gradient(50% 50% at 50% 50%, rgba(117, 149, 255, 0.7) 0%, rgba(117, 149, 255, 0) 100%)"}}/><motion.div className={"framer-1ndugwd"} data-framer-name={"Container"} layoutDependency={layoutDependency} layoutId={"CSSiS9wyB"} style={{backgroundColor: "var(--token-dcbf9366-25e8-40cb-a413-0c58fea4a46d, rgb(14, 18, 46))", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-VwvFW.framer-3wl2be, .framer-VwvFW .framer-3wl2be { display: block; }", ".framer-VwvFW.framer-1o9n1z2 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 45px; justify-content: center; overflow: hidden; padding: 1px; position: relative; width: 182px; will-change: var(--framer-will-change-override, transform); }", ".framer-VwvFW .framer-lwe2hc { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 40px); left: -20px; overflow: visible; position: absolute; top: -20px; width: 40px; z-index: 1; }", ".framer-VwvFW .framer-1ndugwd { flex: 1 0 0px; height: 1px; overflow: visible; position: relative; width: 100%; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-VwvFW.framer-1o9n1z2 { gap: 0px; } .framer-VwvFW.framer-1o9n1z2 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-VwvFW.framer-1o9n1z2 > :first-child { margin-top: 0px; } .framer-VwvFW.framer-1o9n1z2 > :last-child { margin-bottom: 0px; } }", ".framer-VwvFW.framer-v-1xixgdm .framer-lwe2hc { left: unset; right: -20px; }", ".framer-VwvFW.framer-v-denpk0 .framer-lwe2hc { bottom: -20px; left: unset; right: -20px; top: unset; }", ".framer-VwvFW.framer-v-1pf3lpq .framer-lwe2hc { bottom: -20px; height: var(--framer-aspect-ratio-supported, 50px); top: unset; width: 50px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 45
 * @framerIntrinsicWidth 182
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"wMIbqcvXO":{"layout":["fixed","fixed"]},"hadyDTebR":{"layout":["fixed","fixed"]},"gUGs0gxEz":{"layout":["fixed","fixed"]},"vlvEtwVCt":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerlm27TZ4NM: React.ComponentType<Props> = withCSS(Component, css, "framer-VwvFW") as typeof Component;
export default Framerlm27TZ4NM;

Framerlm27TZ4NM.displayName = "Helper/Moving BG 3";

Framerlm27TZ4NM.defaultProps = {height: 45, width: 182};

addPropertyControls(Framerlm27TZ4NM, {variant: {options: ["sXDHTLTmI", "wMIbqcvXO", "hadyDTebR", "gUGs0gxEz", "vlvEtwVCt"], optionTitles: ["Variant 1", "Variant 2", "Variant 3", "Variant 4", "Variant 5"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerlm27TZ4NM, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})